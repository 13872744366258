<template>
    <div class="d-flex flex-column flex-md-row align-items-start px-3 pt-3 pb-2 mt-1 comment-layout" :class="getClass(index)" @click="onClick" v-bind="bindData">
        <div class="d-flex align-items-center gap-2">
            <vnodes :vnodes="checkbox()" />
            <vnodes :vnodes="images()" />
        </div>

        <div class="d-flex flex-column flex-grow-1 ms-3">
            <div class="d-flex flex-column flex-md-row align-items-md-center justify-content-between mt-3 mt-md-0 gap-md-3">
                <vnodes v-if="getItem('title')" :vnodes="getItem('title')" />
                <vnodes :vnodes="getItem('status')" />
            </div>

            <div class="d-flex flex-column flex-md-row gap-md-4 mt-2 mt-md-1">
                <vnodes v-if="getItem('author')" :vnodes="getItem('author')" />
                <vnodes v-if="getItem('created')" :vnodes="getItem('created')" />
            </div>

            <vnodes v-if="getItem('content')" :vnodes="getItem('content')" class="mt-1" />

            <vnodes :vnodes="getItem('actions')" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject, provide, useSlots, useAttrs } from 'vue';
import { Header, travel } from '../helpers';

export interface Props
{
    index: number;
    item: any;
    headers: Header[];
}

defineOptions({
    name: 'list-view-utils-flex',
    inheritAttrs: false,
    components: {
        'vnodes': (props: Record<string, any>, context: any) => props.vnodes
    }
});

const $slots = useSlots();
const $attrs = useAttrs();
const props = defineProps({
  "index": null,
  "item": null,
  "headers": null
});
const item = computed(() => props.item);
const rowClass = inject<(item: any, i: number) => Record<string, boolean> | string[] | string>('row-class');
const rowClick = inject<(item: any, index: number, e: PointerEvent) => any>('row-click');

const bindData = computed(() => ({
    'data-draggable': $attrs['data-draggable'],
    draggable: $attrs.draggable,
    style: $attrs.style
}));

provide('item', item);

const draggable = inject<boolean>('draggable');

const getClass = (index: number): any =>
{
    let result: any = {
        'draggable': draggable
    };

    if (typeof props.item.result.status === 'string')
    {
        result[`comment-layout-${props.item.result.status.toLowerCase?.()}`] = true;
    }

    if (rowClass)
    {
        result = Object.assign(result, rowClass(props.item, props.index));
    }

    return result;
};

const onClick = (e: any): void =>
{
    if (rowClick && props.item)
        rowClick(props.item, props.index, e);
};

const cells = (): any[] =>
{
    const allowed = [
        'list-view-header',
        'list-view-item',
        'list-view-label',
        'list-view-buttons',
        'list-view-image',
        'list-view-checkbox'
    ];
    const prefix = (new Date()).getTime();

    return travel($slots.default(), allowed)
        .map((p, i) =>
        {
            p.key = `${prefix}-${i}`;
            p.props = p.props || {};
            p.props.index = i;
            p.props.header = props.headers[i];

            return p;
        })
        .orderBy(p => p.props.header.position)
        .toArray();
};

const filter = (tagName: string): any[] =>
{
    return cells().filter(p => p.type.name == tagName);
};

const items = (): any[] =>
{
    return filter('list-view-item');
};

const getItem = (name: 'title'|'author'|'created'|'content'|'actions'|'status'): any =>
{
    return items().find(el => el.props.name === name);
};

const images = (): any[] =>
{
    return filter('list-view-image');
};

const checkbox = (): any[] =>
{
    return filter('list-view-checkbox');
};
</script>

<style lang="scss">
.comment-layout {
    border-left: 3px solid transparent;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    cursor: pointer;

    &.checked {
        background: var(--bs-primary-bg-subtle) !important;
    }

    &-rejected {
        border-color: var(--bs-danger);
    }
    &-waiting {
        border-color: var(--bs-warning);
    }
    &-accepted {
        border-color: var(--bs-success);
    }
}
</style>
